import React from "react";
import Page from "../components/Page";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Page>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>OBS! Denne siden finnes ikke.</p>
  </Page>
);

export default NotFoundPage;
